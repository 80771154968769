<template>
  <div class="information" id="information">
    <TopNav></TopNav>
    <div class="first"></div>
    <div class="two">
      <div class="fy_center_box">
        <div class="nav_box">
          <div :class="['nav_item','nav_item1',currentIndex==1?'active':'']" @click="changeNav(1)">公司动态</div>
          <div :class="['nav_item',currentIndex==0?'active':'']" @click="changeNav(0)">行业资讯</div>
        </div>
<!--        <div v-show="isLoading">-->
<!--          <div :key="index" class="news_box" v-for="(num,index) in loadNum">-->
<!--            <content-loader-->
<!--                :height="480"-->
<!--                :speed="2"-->
<!--                :width="1200"-->
<!--                primaryColor="#f3f3f3"-->
<!--                secondaryColor="#ecebeb"-->
<!--            >-->
<!--              <rect x="460" y="8" rx="3" ry="3" width="600" height="20" />-->
<!--              <rect x="460" y="43" rx="3" ry="3" width="600" height="100" />-->
<!--              <rect x="460" y="200" rx="3" ry="3" width="600" height="20" />-->
<!--              <rect height="220" rx="0" ry="0" width="390" x="-0.31" y="-0.33"/>-->
<!--            </content-loader>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div v-show="!isLoading">-->
        <div  v-loading="isLoading" style="min-height: 200px">
          <div class="news_boxs" v-for="(item,i) in dataList" :key="i">
            <el-card shadow="always" >
              <div class="news-card" @click="toDetail(item.id)">
                <img class="img-box" :src="item.img"/>
                <div class="news-right">
                  <div class="news-right1">{{ item.title }}</div>
                  <div class="news-right2">
                    {{item.info}}
                  </div>
                  <div class="news-right3">
                    <div  class="news-right3-time">
                      <img src="../static/img/inf/img2.png" height="18" width="18"/>
                      <span>{{ item.time }}</span>
                    </div>
                    <div class="news-right3-to">
                      <img src="../static/img/inf/img1.png" height="17" width="36"/>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
        </div>
        <div v-if="isLoading" style="min-height: 1200px;width: 100%"></div>
      </div>
    </div>
    <div :class="['three',all<2||all==2?'hiden':'']">
      <div class="fy_center_box">
        <el-pagination
            :pageSize="params.limit"
            :total="all"
            @current-change="currentChange"
            :current-page.sync="currentPage"
            background
            layout="prev, pager, next"
        ></el-pagination>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";
import TopNav from '@/components/Nav/TopNav.vue'
import Footer from '@/components/Footer/Footer.vue'
import {ContentLoader} from 'vue-content-loader'

export default {
  name: 'Information',
  data() {
    return {
      dataList: [],
      lists: [1, 2, 3, 4, 5],
      loadNum: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      isLoading: false,
      currentIndex: 1,
      // 获取数据列表的传参
      params: {
        page: 1,
        limit: 10,
        type: 1 //行业0 公司1
      },
      currentPage: 1,
      hoverIndex: -1, // 菜单hover索引
      all: 0, //总页数
      totalPage: 0 //当前条数
    }
  },
  mounted() {
    this.changeNav(1)
  },
  methods: {
    changeNav(index) {
     this.dataList = []
      this.currentIndex = index
      this.params.type = index
      this.params.page = 1
      this.currentPage = 1

      this.currentChange(1)
    },
    currentChange(val) {
      this.params.page = val
      this.dataListFn()
    },
    //请求数据
    dataListFn() {
      this.isLoading = true
      this.$Api
          .selectlist(this.params)
          .then(res => {
            this.dataList = res.data
            this.all = res.total
              this.isLoading = false
          })
          .catch(error => {
              this.isLoading = false
          })
    },
    //分页
    btnClick(data) {
      //页码点击事件
      if (data != this.params.page) {
        this.params.page = data
      }
      //根据点击页数请求数据
      this.dataListFn()
    },
    pageClick() {
      //根据点击页数请求数据
      this.dataListFn()
    },
    // 跳转详情
    toDetail(item) {
      this.$router.push({
        path: '/NewsDetail',
        query: {
          id: item
        }
      })
    }
  },
  components: {
    TopNav,
    Footer,
    ContentLoader
  }
}
</script>
<style lang="scss" scoped>
.information {
  .first {
    padding-top: 60px;
    width: 100%;
    height: 360px;
    margin-top: 80px;
    background: url("./../static/img/inf/bg.png") no-repeat;
    background-size: cover;
    clear: both;
  }

  .second {
    width: 100%;
    padding-top: 80px;
    padding-bottom: 96px;
    text-align: center;
    background: #fff;

    .title {
      font-size: 42px;
      color: #191e4a;
    }

    .second_title {
      padding-top: 16px;
      padding-bottom: 60px;
      font-size: 28px;
      color: #b6b6c6;
    }

    .des {
      color: #5b607f;
      line-height: 40px;
      padding: 0 140px;
      letter-spacing: 3px;
    }

    .center_img {
      display: block;
      width: 1170px;
      height: 636px;
      margin: 0 auto;
      margin-top: 40px;
    }
  }

  .two {
    width: 100%;
    text-align: center;
    padding-top: 100px;
    background-size: cover;
    margin-bottom: 50px;

    .nav_box {
      height: 60px;
      line-height: 58px;
      font-size: 18px;
      text-align: left;
      margin-bottom: 30px;
      display: inline-block;
      .nav_item1{
        margin-right: 120px;
      }
      .nav_item {
        display: inline-block;
        width: 190px;
        height: 100%;
        line-height: 60px;
        font-size: 18px;
        text-align: center;
        color: #AB7D3E;
        background-image: url("./../static/img/product/p-bg1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        //border-radius: 30px;
        &:hover {
          cursor: pointer;
        }
        &.active {
          background-image: url("./../static/img/product/p-bg2.png");
          color: #fff;
        }
      }

    }

    .news_box {
      width: 1200px;
      height: 348px;
      margin: 20px 5px;
      float: left;
      margin-bottom: 20px;
    }
    .news_boxs{
      width: 1190px;
      height: 270px;
      margin: 20px 5px;
      float: left;
      cursor: pointer;
      margin-bottom: 20px;
      .news-card{
        display: flex;
        justify-content: left;
        align-items: first;
        .news-right{
          padding: 33px 37px 10px 37px;
          text-align: left;
          width: 100%;
          transition: 0.5s;
          .news-right1{
            color: #333333;
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;

          }
          .news-right2{
            color: #666666;
            height: 120px;
            overflow : hidden;
            text-indent: 40px;
            line-height: 30px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;

          }
          .news-right3{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #ddd;
            margin-top: 20px;
            padding-top: 15px;
            .news-right3-time{
              color: #CCCCCC;
              font-size: 14px;
              vertical-align: center;
              span{
                position: relative;
                top: -3px;
                left: 0;
                margin-left: 10px;
              }

            }
            .news-right3-to{

            }

          }
        }
        .news-right:hover{
          background-color: #FFF9F1;
          .news-right1{
            color: #AE7B40;
          }
        }
      }
    }

  }

  .three {
    &.hiden {
      opacity: 0;
    }

    padding-bottom: 64px;

    .el-pagination {
      text-align: center;

      /deep/ .btn-prev,
      /deep/ .btn-next,
      /deep/ .el-pager li {
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        border-radius: 8px;
        color: #999999;
      }

      /deep/ .el-pager li.active {
        color: #fff;
      }

      /deep/ .btn-prev,
      /deep/ .btn-next {
        color: #ffffff;
        background: #d0d0d0;
      }

      /deep/ .el-icon {
        font-size: 16px;
      }
    }
  }
}
/deep/ .el-card__body{
  padding: 0 !important;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color:#AE7B40; //修改后的背景图颜色
  color: #fff;
}
.img-box{
  width: 400px !important;
  height: 260px !important;
  min-width: 400px !important;
  min-height: 260px !important;
}
</style>
