import { render, staticRenderFns } from "./Informations.vue?vue&type=template&id=013e3f0d&scoped=true"
import script from "./Informations.vue?vue&type=script&lang=js"
export * from "./Informations.vue?vue&type=script&lang=js"
import style0 from "./Informations.vue?vue&type=style&index=0&id=013e3f0d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "013e3f0d",
  null
  
)

export default component.exports